.toolbar-button {
  height: 48px;
  color: var(--default-color);
  float: left;
  text-align: center;
  margin: 0 7px;
  padding: 0px 5px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Chrome and Opera */
}
.toolbar-button .toolbar-button-label {
  font-size: 12px;
}
.toolbar-button .expand-caret {
  width: 8px;
  height: 8px;
  transform: translate(2px, 2px);
}
.toolbar-button svg {
  width: 33px;
  height: 33px;
  margin: 2px;
}
.toolbar-button.active {
  border: 2px solid #008181 !important;
  border-radius: 5px !important;
}
.imgSyncButton svg path {
  fill: #ff0;
}
.sync-button svg {
  margin-left: 15px;
}
