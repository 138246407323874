.camera-button svg {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.camera-button {
  position: absolute;
  z-index: 100;
  right: 50px;
  top: 4px;
}
